
p {
  color: #72849a;
}

a {
	color: #ffae8a;
}

a:hover {
	color: #f9d1c0;
}
