/* For the Search component (no token found in ThemeConfig) */

.ant-input-search-button {
	background-color: #ffae8a !important;
	height: 40px !important;
	border-radius: 0px 10px 10px 0px !important;
	box-shadow: none !important;
}

.ant-input-search-button:hover {
	background-color: #fdd1be !important;
}

.ant-input:hover,:focus{
	/* border-color: #ffae8a !important; */
	box-shadow: none !important;
}