.ant-input-group-addon {
  border-right: none !important;
  background-color: #f7f7f8 !important;
  padding-right: 8px !important;
}

.ant-input-group-addon:focus {
  border-color: #ffccb3;
  box-shadow: 2px 0 0 2px rgba(255, 122, 5, 0.06);
  border-right: none;
  border-left: 1px;
  outline: 0;
}

/* .ant-input-affix-wrapper{
	border-left:none !important;
	background-color: #f7f7f8 !important;
	box-shadow:2px 0 0 1px rgba(255, 122, 5, 0.06);
	padding-left:5px !important;
} */

.ant-form-item-required-mark-optional {
  font-weight: 200 !important;
  font-size: small !important;
  font-style: italic !important;
}

.ant-form-item-label {
  padding-bottom: 2px !important;
}

/* Targeting Ant Design's Input component */
.ant-input {
  font-size: 14px !important; /* default font size for non-mobile devices */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ant-input {
    font-size: 16px !important; /* font size for mobile devices */
  }
}

/* Targeting Ant Design's Number Input component */
.ant-input-number-input {
  font-size: 14px !important; /* default font size for non-mobile devices */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ant-input-number-input {
    font-size: 16px !important; /* font size for mobile devices */
  }
}
