/* CSS for non-mobile devices */
.ant-card-body-custom {
    padding: 20px;
  }
  
  /* CSS for mobile devices */
  @media only screen and (max-width: 767px) {
    .ant-card-body-custom {
      padding: 0px !important;
    }
  }
  