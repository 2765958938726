
.ant-tag-checkable {
  background-color: white !important;
  border: 1px solid #e6ebf1 !important;
  margin: 1px !important
}

.ant-tag-checkable:hover {
  background-color: black !important;
  color: white !important;
}

.ant-tag-checkable-checked {
  background-color: black !important;
  border: 1px solid #455560 !important;
  color: white !important;
  cursor: default;
}
